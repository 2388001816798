html {
	position: relative;
	min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  margin-bottom: 60px;
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 60px;
	line-height: 60px;
	background-color: #f5f5f5;
}
.body > .container {
	padding: 60px 15px 0;
}
.fotter > .container {
	padding-right: 15px;
	padding-left: 15px;
}
